import { render, staticRenderFns } from "./PandoraPage.vue?vue&type=template&id=6a13d419&scoped=true"
import script from "./PandoraPage.vue?vue&type=script&lang=js"
export * from "./PandoraPage.vue?vue&type=script&lang=js"
import style0 from "./PandoraPage.vue?vue&type=style&index=0&id=6a13d419&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a13d419",
  null
  
)

export default component.exports